.timepoint-calendar{
    display: flex;
    flex-direction: column;
}

.timepoint-calendar ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.timepoint-calendar ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: white;
    font-weight: bold;
    flex: 0 0 11.11%;
    text-align: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    flex-direction: column;
}

@media screen and (max-width: 992px){
    .timepoint-calendar ul{
        max-width: 100%;
    }
    .timepoint-calendar ul li{
        flex: 0 0 25%;
    }
    
}