.main-wrapper {
  max-width: 100%;
  padding: 32px;
}

.main-wrapper .row {
  max-width: 100%;
}

.event-settings-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 16px;
}
.event-settings-actions button {
  color: #181821;
}

.actions-button-wrapper button {
  padding: 20px;
  font-size: 24px;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 230px;
  height: 230px;
  outline: none;
  flex:0 0 50%;
}

.actions-button-wrapper button span,  
.actions-button-wrapper button h1{
  width: 100%;
  text-align: center;
  display: inline-block;
}


@media screen and (max-width: 992px) {
  .actions-button-wrapper button {
    width: 50%;
    height: 120px;
    flex: 1;
  }
  .main-wrapper .row{
    flex-direction: column;
    max-width: none;
  }
}
