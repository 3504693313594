.timeline-selector {
    position: relative;
    width: 100%;
    height: 100px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline-line {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    display: flex;
    align-items: center;
  }
  
  .timeline-dot {
    position: absolute;
    top: -12px; /* Center the dot vertically */
    width: 24px;
    height: 24px;
    background-color: #54f8a6;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .timeline-dot:hover .timeline-popup {
    display: block;
  }
  
  .timeline-popup {
    display: none;
    position: absolute;
    top: -100px; /* Above the dot */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 8px;
    white-space: nowrap;
    font-size: 12px;
    color: #333;
    z-index: 10;
  }
  
  .timeline-popup:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  